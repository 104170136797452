@use "../config/" as *;
.waf-shop-landing {
    @extend %p-8-4;
    @extend %pure-white-900-bg;
    .head-wrap {
        @extend %flex-column-c-fs;
        @extend %gap-2;
        @extend %mb-7;
        @extend %secondary-800;
        .title {
            @extend %font-32-pb;
        }
        .sub-title {
            line-height: var(--space-5);
            @extend %font-14-pr;
            @extend %neutral-800;
        }
    }
    .waf-body {
        .title {
            @extend %font-20-pb;
            @extend %mb-5;
            @extend %uppercase;
        }
    }
    .article {
        &-list {
            @extend %flex;
            @extend %gap-4;
            flex-wrap: wrap;
        }
        &-item {
            border-radius: 0.5rem;
            @extend %neutral-50-bg;
        }
        &-title {
            @extend %font-16-pb;
            @extend %neutral-800;
            @extend %uppercase;
            @extend %mb-2;
        }
        &-description {
            p {
                line-height: var(--space-4);
                @include truncate-text(6);
                @extend %font-10-pr;
                @extend %neutral-800;
                @extend %mb-4;
            }
        }
        &-thumbnail {
            .img-box {
                @extend %transparent-bg;
            }
        }
        &-shop {
            .btn {
                font-size: 0.9rem;
                font-weight: 700;
                width: max-content;
                height: 3rem;
                border: .1rem solid var(--secondary-800);
                @extend %secondary-800;
                @extend %half-radius;
                @extend %uppercase;
                @extend %flex-c-c;
                @extend %gap-2;
                @extend %p-2;
                &:after {
                    font-weight: 500;
                    width: 1.4rem;
                    @include icon(drop-arrow, 10);
                }
                &::before {
                    width: 1.4rem;
                    height: .1rem;
                    background-color: var(--neutral-900);
                }
            }
        }
        &-art {
            @extend %p-6;
            .article {
                &-content {
                    @extend %mb-2;
                }
                &-thumbnail {
                    .img-box {
                        img {
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        &-magazine {
            .article {
                &-wrap {
                    @extend %flex;
                    @extend %h-100;
                }
                &-content {
                    @extend %py-7;
                    @extend %pl-5;
                    @extend %w-50;
                }
                &-thumbnail {
                    @extend %relative;
                    @extend %w-50;
                    .img-box {
                        width: 22rem;
                        right: 0;
                        object-position: left;
                        @extend %h-100;
                        @extend %absolute;
                        img {
                            object-fit: cover;
                            object-position: left;
                        }
                    }
                }
            }
        }
        &-books {
            .article {
                &-wrap {
                    @extend %flex;
                    @extend %h-100;
                }
                &-thumbnail {
                    width: 55%;
                    .img-box {
                        @extend %h-100;
                        img {
                            object-fit: contain;
                        }
                    }
                }
                &-content {
                    width: 45%;
                    @extend %p-7-5;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-shop-landing {
        .article {
            &-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
            &-art {
                grid-area: 1 / 1 / 3 / 2;
            }
            &-magazine {
                grid-area: 1 / 2 / 2 / 3;
            }
            &-books {
                grid-area: 2 / 2 / 3 / 3;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-shop-landing {
        .head-wrap {
            align-items: center;
            .title {
                font-size: var(--space-10);
            }
            .sub-title {
                font-size: var(--space-4);
            }
        }
        .article {
            &-title {
                font-size: var(--space-6);
            }
            &-description {
                p {
                    font-size: 1.4rem;
                    line-height: var(--space-5);
                }
            }
            &-shop {
                .btn {
                    height: 3.4rem;
                    font-size: var(--space-3);
                    transition: .3s;
                    &::before {
                        content: '';
                        transition: .3s;
                        background-color: var(--neutral-900);
                    }
                    &::after {
                        width: 0;
                        overflow: hidden;
                        transition: .3s;
                    }
                    &:hover {
                        &::before {
                            width: 0;
                            overflow: hidden;
                        }
                        &::after {
                            width: 1.4rem;
                            @include icon(drop-arrow, 12);
                        }
                    }
                }
            }
            &-art {
                padding: var(--space-8) 4.2rem;
            }
            &-magazine {
                grid-area: 1 / 2 / 2 / 3;
                .article {
                    &-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 0 0 var(--space-8);
                    }
                    &-thumbnail {
                        .img-box {
                            width: 35rem;
                        }
                    }
                }
            }
            &-books {
                .article {
                    &-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 var(--space-8);
                    }
                }
            }
        }
    }
}